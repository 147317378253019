
* {
     box-sizing: border-box;
}

.Home {
    max-width: 1440px;
    width: 100%;
}


.HomeBackground {
    width: 100%;
    height: 1241px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.HomeBackgroundBg {
    background-image: url(./../../Assets/Frame\ 327.png);
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    right: 20px;
    width: 1400px;
    height: 900px;
    border-radius: 30px;
    z-index: -1;
}

.HomeBackgroundTitle {
    position: relative;
    top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.HomeBackgroundTitle h2 {
    padding: 0;
    margin: 0;
    font-family: BoldPro;
    font-weight: 700;
    font-size: 42px;
    line-height: 52.79px;
    color: #000C60;
}

.HomeBackgroundTitle h2 span {
    background: linear-gradient(90deg, #0017BC 4.5%, #6A08FC 31.5%, #7D3FC7 64%, #E93FB8 98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.HomeBackgroundTitle p:nth-child(2) {
    font-size: 20px;
    font-family: Regular;
    font-weight: 400;
    line-height: 25.14px;
    color: #000C60;
    padding: 0;
    margin: 0;
    margin-bottom: 24px;
}

/* .HomeBackgroundTitle button {
    width: 166px;
    height: 48px;
    border-radius: 6px;
    border: none;
    background-color: #000C60;
    color: #fff;
    font-size: 18px;
    font-family: Regular;
    line-height: 22.63px;
    font-weight: 400;
    margin-bottom: 4px;
    cursor: pointer;
    transition: .3s ease all;
} */


.HomeBackgroundTitle button {
    width: 166px;
    height: 48px;
    border-radius: 6px;
    border: none;
    background-color: #000C60;
    color: #fff;
    font-size: 18px;
    font-family: Regular;
    line-height: 22.63px;
    font-weight: 400;
    margin-bottom: 4px;
    cursor: pointer;
    transition: 1s ease all;
    position: relative;
    z-index: 30;
    overflow: hidden;
}

.HomeBackgroundTitle button::after {
    content: '';
    position: absolute;
    height: 0.25rem;
    width: 0.25rem;
    background-color: #1AFFC3;
    left: 50%;
    bottom: 0;
    transform: translateY(100%);
    border-radius: 0.375rem;
    z-index: -20;
    transition: all 1s;
}

.HomeBackgroundTitle button:hover::after {
    transform: scale(300);
    transition: all 1s;
}

.HomeBackgroundTitle button:hover {
    transition: all 1s;
    color: #000C60;
}


.HomeBackgroundTitle p:nth-child(4) {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.08px;
    font-family: Regular;
    color: #000C60;
    padding: 0;
    margin: 0;
    margin-bottom: 48px;
}

.HomeBackgroundCrmImg {
    position: relative;
    top: 200px;
    box-shadow: 0px 0px 23.8px 0px #0000002E;
    border-radius: 30px;
}

.SecondBoxHome {
    width: 1400px;
    /* height: 900px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 10px 10px 10px;
    text-align: center;
}

.SecondBoxHomeTitle h2 {
    font-size: 42px;
    font-weight: 900;
    font-family: BlackPro;
    line-height: 52.79px;
    color: #000C60;
    padding: 0;
    margin: 0;
}

.SecondBoxHomeTitle p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    font-family: Regular;
    color: #000C60;
    padding: 0;
    margin: 0;
}

.SecondBoxHomeContant {
    display: flex;
    gap: 20px;
    width: 1070px;
    height: 630px;
    padding: 20px;
    margin-top: 10px;
}

.SecondBoxHomeContantLeft_Up {
    display: flex;
    align-items: center;
    /* width: 735px; */
    height: 180px;
    padding: 54px 55px 54px 30px;
    gap: 20px;
    position: relative;
    background-color: #EDF7FF;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    cursor: pointer;
    position: relative;
    z-index: 30;
    overflow: hidden;
}


.SecondBoxHomeContantLeft_Up::after {
    content: '';
    position: absolute;
    height: 0.25rem;
    width: 0.25rem;
    background: linear-gradient(79.03deg, #005EEB 8.99%, #257AFD 48.29%, #00F9FF 87.6%);
    left: 75px;
    top: 110px;
    bottom: 0;
    transform: translateY(100%);
    border-radius: 0.375rem;
    z-index: -10;
    transition: all 1s;
}

.SecondBoxHomeContantLeft_Up:hover::after {
    transform: scale(500);
    transition: all 1s;
}

.SecondBoxHomeContantLeft_Up:hover {
    transition: all 1s;
    color: #000C60;
}

.SecondBoxHomeContantLeft_Up:hover .Users {
    background: #FFFFFF;
}

.Users {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    background: linear-gradient(75.79deg, #00F9FF 2.37%, #257AFD 64.84%, #005EEB 106.28%);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s ease all;
}

#users {
    display: flex;
    transition: .3s ease all;
}

#white_users {
    display: none;
    transition: .3s ease all;
}

.SecondBoxHomeContantLeft_Up:hover #users {
    display: none;
}

.SecondBoxHomeContantLeft_Up:hover #white_users {
    display: flex;
}

.SecondBoxHomeContantLeft_Up .Title {
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.SecondBoxHomeContantLeft_Up .Title h2 {
    padding: 0;
    margin: 0;
    font-size: 25px;
    font-weight: 900;
    line-height: 31.42px;
    font-family: BlackPro;
    color: #000C60;
}

.SecondBoxHomeContantLeft_Up:hover .Title h2 {
    color: #FFF;
}

.SecondBoxHomeContantLeft_Up .Title p {
    font-size: 20px;
    font-weight: 400;
    line-height: 25.14px;
    font-family: Regular;
    color: #000C60;
    padding: 0;
    margin: 0;
}

.SecondBoxHomeContantLeft_Up:hover .Title p {
    color: #FFF;
}

.SecondBoxHomeContantLeft_Up .icon {
    position: absolute;
    bottom: 0;
    right: 0;
}

@media (max-width: 768px) {
    .SecondBoxHomeContantLeft {
        width: inherit;
    }

    .SecondBoxHomeContantLeft_Up{
        width: 90%;
        height: inherit;
        padding: 30px 20px;
        margin-left: 20px;
    }

    .SecondBoxHomeContantLeft_DownBox {
        width: 90% !important;
        height: inherit !important;
        padding: 20px;
    }

    .SecondBoxHomeContantRight {
        width: 90%;
        height: inherit !important;
    }

    .SecondBoxHomeContantLeft_DownBox .Users {
        width: 30% !important;
        height: 80px !important;
    }

    .SecondBoxHomeContantLeft_Up .Title h2 {
        font-size: 24px;
    }
    .SecondBoxHomeContantLeft_Up .Title p {
        font-size: 14px;
    }

    .SecondBoxHomeContantLeft_DownBox .Title h2{
        font-size: 24px;
    }

    .SecondBoxHomeContantLeft_DownBox .Title p {
        font-size: 14px !important;
    }

    .SecondBoxHomeContantLeft_Up .icon {
        display: none !important;
    }

    .SecondBoxHomeContantLeft_Up .Users {
        width: 180px !important;
        height: 80px !important;
    }

    #users {
        width: 40px !important;
    }

    .SecondBoxHomeContantRightTitle h2 {
        font-size: 20px;
    }

    .SecondBoxHomeContantRightTitle p {
        font-size: 14px !important;
        line-height: 18px !important;
    }
}

.SecondBoxHomeContantLeft_Up:hover .icon img:nth-child(1),
.SecondBoxHomeContantRight:hover .icon img:nth-child(1) {
    display: none;
}

.SecondBoxHomeContantLeft_Up:hover .icon img:nth-child(2),
.SecondBoxHomeContantRight:hover .icon img:nth-child(2) {
    display: flex;
}

.icon img:nth-child(1) {
    display: flex;
    transition: .3s ease all;
}

.icon img:nth-child(2) {
    display: none;
    transition: .3s ease all;
}

.SecondBoxHomeContantLeft_Down {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
}

.SecondBoxHomeContantLeft_DownBox {
    position: relative;
    width: 395px;
    height: 150px;
    display: flex;
    align-items: center;
    padding: 54px 30px;
    gap: 20px;
    background: #EDF7FF;
    border-radius: 20px;
    transition: background-color 0.3s ease;
    position: relative;
    z-index: 30;
    overflow: hidden;
    cursor: pointer;
}

.SecondBoxHomeContantLeft_DownBox::after {
    content: '';
    position: absolute;
    height: 0.25rem;
    width: 0.25rem;
    background: linear-gradient(79.03deg, #005EEB 8.99%, #257AFD 48.29%, #00F9FF 87.6%);
    left: 75px;
    top: 80px;
    bottom: 0;
    transform: translateY(100%);
    border-radius: 0.375rem;
    z-index: -30;
    transition: all 1s;
}

.SecondBoxHomeContantLeft_DownBox:hover::after {
    transform: scale(500);
    transition: all 1s;
}

.SecondBoxHomeContantLeft_DownBox:hover {
    transition: all 1s;
    color: #000C60;
}

.SecondBoxHomeContantLeft_DownBox:hover .Users {
    background: #FFF;
}

.SecondBoxHomeContantLeft_DownBox:hover #users {
    display: none;
}

.SecondBoxHomeContantLeft_DownBox:hover #white_users {
    display: flex;
}

.SecondBoxHomeContantLeft_DownBox .Title {
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.SecondBoxHomeContantLeft_DownBox .Title h2 {
    font-size: 20px;
    font-weight: 900;
    line-height: 25.14px;
    font-family: BlackPro;
    color: #000C60;
    padding: 0;
    margin: 0;
}

.SecondBoxHomeContantLeft_DownBox:hover .Title h2 {
    color: #FFF;
}

.SecondBoxHomeContantLeft_DownBox .Title p {
    font-size: 18px;
    font-weight: 400;
    line-height: 22.63px;
    font-family: Regular;
    color: #000C60;
    padding: 0;
    margin: 0;
}

.SecondBoxHomeContantLeft_DownBox:hover .Title P {
    color: #FFF;
}

.SecondBoxHomeContantRight {
    min-width: 200px;
    height: 522px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 54px 15px 54px 15px;
    background: #EDF7FF;
    border-radius: 20px;
    position: relative;
    transition: background-color 0.3s ease;
    overflow: hidden;
    cursor: pointer;
    z-index: 30;
    position: relative;

}

.SecondBoxHomeContantRight::after {
    content: '';
    position: absolute;
    height: 0.25rem;
    width: 0.25rem;
    background: linear-gradient(79.03deg, #005EEB 8.99%, #257AFD 48.29%, #00F9FF 87.6%);
    left: 112px;
    top: 100px;
    bottom: 0;
    transform: translateY(100%);
    border-radius: 0.375rem;
    z-index: -30;
    transition: all 1s;
}

.SecondBoxHomeContantRight:hover::after {
    transform: scale(500);
    transition: all 1s;
}

.SecondBoxHomeContantRight:hover {
    transition: all 1s;
    color: #000C60;
}

.SecondBoxHomeContantRight:hover .Users {
    background: #FFF;
}

.SecondBoxHomeContantRight:hover #users {
    display: none;
}

.SecondBoxHomeContantRight:hover #white_users {
    display: flex;
}

.SecondBoxHomeContantRightTitle {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 20px;
}

.SecondBoxHomeContantRightTitle h2 {
    font-size: 20px;
    font-family: BlackPro;
    line-height: 25.14px;
    font-weight: 900;
    color: #000C60;
    padding: 0;
    margin: 0;
}

.SecondBoxHomeContantRight:hover .SecondBoxHomeContantRightTitle h2 {
    color: #FFF;
}

.SecondBoxHomeContantRightTitle p {
    font-size: 18px;
    font-weight: 400;
    line-height: 22.63px;
    font-family: Regular;
    color: #000C60;
    padding: 0;
    margin: 0;
}

.SecondBoxHomeContantRight:hover .SecondBoxHomeContantRightTitle p {
    color: #FFF;
}

.SecondBoxHomeContantRight .icon {
    position: absolute;
    width: 200px;
    height: auto;
    bottom: -55px;
    right: 0;
}

.SecondBoxHomeContantRight .icon img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.SecondDawn {
    width: 100%;
    margin-top: 30px;
    text-align: center;
}

.SecondDawn p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    font-family: Regular;
    color: #2D2D2D;
    padding: 0;
    margin: 0;
}

.TheardBoxHome {
    width: 1440px;
    /* height: 900px; */
    display: flex;
    flex-direction: column;
    gap: 9px;
    position: relative;
}

.TheardBoxHomeTitle {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.TheardBoxHomeTitle h2 {
    font-size: 42px;
    font-weight: 900;
    line-height: 52.79px;
    color: #000C60;
    font-family: BlackPro;
    padding: 0;
    margin: 0;
}

.TheardBoxHomeTitle p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    font-family: Regular;
    color: #000C60;
    padding: 0;
    margin: 0;
}

.TheardBoxHomeBox {
    width: 1440px;
    /* height: 900 !important; */
    display: flex;
    justify-content: center;
}

.TheardBoxHomeBoxLeft {
    width: 877px;
    height: 687px;
    position: relative;
}

.TheardBoxHomeBoxRight {
    width: 415px;
    height: 234px;
    display: flex;
    flex-direction: column;
}

.TheardBoxHomeBoxLeftImg {
    position: absolute;
    top: 45px;
    left: 0;
    border-radius: 10px;
    box-shadow: 0px 0px 12.4px 0px #00000040;
}

.Boxsms_One {
    position: absolute;
    top: 20px;
    left: 41px;
    width: 153px;
    height: 141px;
    border-radius: 6px;
    padding: 10px;
    box-shadow: 0px 0px 20px 0px #00000040;
    backdrop-filter: blur(8.800000190734863px);
    border: 1px solid var(--Colors-Light-gray-30, #EBEFF5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: .3s ease all;
    cursor: pointer;
}

.Boxsms_Two {
    position: absolute;
    bottom: 0px;
    left: 129px;
    width: 153px;
    height: 141px;
    border-radius: 6px;
    padding: 10px;
    box-shadow: 0px 0px 20px 0px #00000040;
    backdrop-filter: blur(8.800000190734863px);
    border: 1px solid var(--Colors-Light-gray-30, #EBEFF5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: .3s ease all;
    cursor: pointer;
}

.Boxsms_Three {
    position: absolute;
    right: 0px;
    bottom: 116px;
    width: 153px;
    height: 141px;
    border-radius: 6px;
    padding: 10px;
    box-shadow: 0px 0px 20px 0px #00000040;
    backdrop-filter: blur(8.800000190734863px);
    border: 1px solid var(--Colors-Light-gray-30, #EBEFF5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: .3s ease all;
    cursor: pointer;
}

.Boxsms_One:hover {
    top: 0px;
}

.Boxsms_Two:hover {
    bottom: 20px;
}

.Boxsms_Three:hover {
    bottom: 136px;
}

.Boxsms_OneTop {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}

.Boxsms_OneTop span {
    font-size: 16px;
    color: #2ED47A;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Boxsms_OneTop p {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.2px;
    letter-spacing: 1%;
    font-family: SamiBold;
    color: #192A3E;
    padding: 0;
    margin: 0;
}

.Boxsms_OneMid {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Boxsms_OneMidDars {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 93px;
    height: 19px;
    border-radius: 4px;
    background-color: #33A9FF;
}

.Boxsms_OneMidDars span {
    display: flex;
    font-size: 16px;
    color: white;
}

.Boxsms_OneMidDars p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.2px;
    font-family: RegularGen;
    letter-spacing: 1%;
    color: #FFFFFF;
    padding: 0;
    margin: 0;
    margin-top: 3px;
}

.Boxsms_OneMid_info_Box {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
}

.Boxsms_OneMid_info {
    display: flex;
    gap: 10px;
    align-items: center;
}

.Boxsms_OneMid_info span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Boxsms_OneMid_info p {
    font-size: 12px;
    font-family: MediumGen;
    font-weight: 500;
    line-height: 16.2px;
    letter-spacing: 1%;
    color: #192A3E;
    padding: 0;
    margin: 0;
}

.Boxsms_OneBottom {
    display: flex;
}

.Boxsms_OneBottom p {
    font-size: 10px;
    font-weight: 400;
    line-height: 13.5px;
    letter-spacing: 1%;
    font-family: RegularGen;
    color: #334D6E;
    padding: 0;
    margin: 0;
}

.Boxsms_OneBottom div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Boxsms_OneBottom div span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Boxsms_OneBottom div:nth-child(1) p {
    margin-right: 10px;
}

.Boxsms_OneBottom div:nth-child(2) p {
    margin-right: 6px;
}

.TheardBoxHomeBoxRight {
    margin-top: 99px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: -10px;
}

.TheardBoxHomeBoxRight p:nth-child(1) {
    font-size: 14px;
    font-weight: 400;
    line-height: 17.6px;
    letter-spacing: 1%;
    font-family: Regular;
    color: #257AFD;
    margin: 0;
    padding: 0;
}

.TheardBoxHomeBoxRight h2 {
    padding: 0;
    margin: 0;
    margin-top: 4px;
    width: 415px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    background: linear-gradient(75.79deg, #005EEB 2.37%, #257AFD 43.81%, #00F9FF 106.28%);
    font-size: 36px;
    line-height: 45.25px;
    letter-spacing: 1%;
    font-weight: 900;
    font-family: BlackPro;
    color: var(--White, #FFFFFF);
    box-shadow: 0px 0px 8px 0px #00000033;

}

.TheardBoxHomeBoxRight p:nth-child(3) {
    font-size: 18px;
    font-weight: 400;
    line-height: 22.63px;
    letter-spacing: 1%;
    font-family: Regular;
    color: #257AFD;
    margin: 0;
    padding: 0;
    color: #2D2D2D;
    margin-top: 10px;
}


.TheardBoxHomeBoxRight button {
    width: 166px;
    height: 48px;
    border-radius: 6px;
    border: none;
    background-color: #000C60;
    color: #fff;
    font-size: 18px;
    font-family: Regular;
    line-height: 22.63px;
    font-weight: 400;
    margin-bottom: 4px;
    cursor: pointer;
    transition: 1s ease all;
    position: relative;
    z-index: 30;
    overflow: hidden;
    margin-top: 20px;
}

.TheardBoxHomeBoxRight button::after {
    content: '';
    position: absolute;
    height: 0.25rem;
    width: 0.25rem;
    background-color: #1AFFC3;
    left: 50%;
    bottom: 0;
    transform: translateY(100%);
    border-radius: 0.375rem;
    z-index: -20;
    transition: all 1s;
}

.TheardBoxHomeBoxRight button:hover::after {
    transform: scale(300);
    transition: all 1s;
}

.TheardBoxHomeBoxRight button:hover {
    transition: all 1s;
    color: #000C60;
}

.LineGrande {
    position: absolute;
    bottom: 45px;
    z-index: -1;
}

.FourBoxHomeBox {
    width: 1440px;
    height: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.FourBoxHome {
    width: 1440px;
    height: 900px;
    display: flex;
    justify-content: center;
}

.FourBoxHomeRight {
    width: 1003px;
    height: 665px;
    position: relative;
}

.FourBoxImg {
    position: absolute;
    top: 75px;
    right: 47px;
    width: 834px;
    height: 521px;
    border-radius: 10px;
}

.FourBoxOne {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.FourBoxTwo {
    position: absolute;
    bottom: 14px !important;
    left: 0px !important;
    z-index: -1;
}

.FourBoxSms {
    position: absolute;
    top: 34px;
    right: 31px;
    width: 256px;
    height: 72px;
    padding: 10px;
    border: 1px solid #EBEFF5;
    box-shadow: 0px 0px 20px 0px #00000040;
    backdrop-filter: blur(8.800000190734863px);
    font-size: 14px;
    font-weight: 400;
    line-height: 17.6px;
    font-family: Regular;
    color: #2D2D2D;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    transition: .3s ease all;
    cursor: pointer;
}

.FourBoxSms:hover {
    top: 14px;
}

.FourBoxBgc {
    position: absolute;
    bottom: 0;
    right: 6px;
    z-index: -2;
}

.FourBoxHomeLeft {
    width: 404px;
    height: 303px;
}

.FourBoxHomeTitle {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.FourBoxHomeTitle h2 {
    font-size: 42px;
    font-family: BlackPro;
    font-weight: 900;
    color: #000C60;
    line-height: 52.79px;
    padding: 0;
    margin: 0;
}

.FourBoxHomeTitle p {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    font-family: Regular;
    color: #000C60;
    margin-bottom: 7px;
}

.FourBoxHomeLeft {
    margin-top: 118px;
    width: 404px;
    height: 303px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: -83px;
}

.FourBoxHomeLeft p:nth-child(1) {
    font-family: Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.6px;
    letter-spacing: 0.11em;
    color: var(--Light-blue, #257AFD);
    padding: 0;
    margin: 0;
}

.FourBoxHomeLeft h2 {
    padding: 0;
    margin: 0;
    width: 382px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 14px;
    opacity: 0px;
    background: linear-gradient(75.79deg, #005EEB 2.37%, #257AFD 43.81%, #00F9FF 106.28%);
    font-size: 36px;
    font-weight: 900;
    line-height: 45.25px;
    font-family: BlackPro;
    color: #FFF;
    box-shadow: 0px 0px 8px 0px #00000033;
    margin-top: 4px;
}

.FourBoxHomeLeft p:nth-child(3) {
    font-family: Regular;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.63px;
    text-align: left;
    color: #2D2D2D;
    padding: 0;
    margin: 0;
    margin-top: 10px;
}

.FourBoxHomeLeft button {
    width: 166px;
    height: 48px;
    border-radius: 6px;
    border: none;
    background-color: #000C60;
    color: #fff;
    font-size: 18px;
    font-family: Regular;
    line-height: 22.63px;
    font-weight: 400;
    margin-bottom: 4px;
    cursor: pointer;
    transition: 1s ease all;
    position: relative;
    z-index: 30;
    overflow: hidden;
    margin-top: 20px;
}

.FourBoxHomeLeft button::after {
    content: '';
    position: absolute;
    height: 0.25rem;
    width: 0.25rem;
    background-color: #1AFFC3;
    left: 50%;
    bottom: 0;
    transform: translateY(100%);
    border-radius: 0.375rem;
    z-index: -20;
    transition: all 1s;
}

.FourBoxHomeLeft button:hover::after {
    transform: scale(300);
    transition: all 1s;
}

.FourBoxHomeLeft button:hover {
    transition: all 1s;
    color: #000C60;
}








/* murod */

/* 5 section */
.FiveBoxHome {
    max-width: 1440px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 76px;
    background: linear-gradient(131.16deg, rgba(213, 236, 255, 0) 34.07%, #D5ECFF 84.16%);
}

.FiveBoxHomeLeft_sms {
    max-width: 256px;
    width: 100%;
    border-radius: 6px;
    padding: 10px;
    border: 1px solid #EBEFF5;
    box-shadow: 0px 0px 20px 0px #00000040;
    backdrop-filter: blur(8.800000190734863px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -48px;
    right: 39px;
    z-index: 1;
    cursor: pointer;
    transition: .3s ease all;
}

.FiveBoxHomeLeft_sms:hover {
    top: -68px;
}

.FiveBoxHomeLeft_sms p {
    font-size: 14px;
    font-weight: 400;
    line-height: 17.6px;
    font-family: Regular;
    color: #2D2D2D;
    padding: 0;
    margin: 0;
}

.FiveBoxHomeLeft {
    width: 60%;
    height: 459px;
    margin-right: 40px;
    position: relative;
    background-image: url("./../../Assets/fivesectionimg.png");
}


.FiveBoxHomeLeft_boxTwo {
    position: absolute;
    bottom: -36px;
    left: -22px;
    z-index: 1;
   
}
.FiveBoxHomeLeft_boxTwo img {
    display: inline-block;
    width: 166px;
    height: 107px;
}

.FiveBoxHomeLeft_boxOne {
    position: absolute;
    top: -75px;
    right: 19px;
    z-index: -1;
}

.FiveBoxHomeBoxTitle {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 62px;
}

.FiveBoxHomeBoxTitle h2 {
    font-size: 42px;
    font-family: BlackPro;
    line-height: 52.79px;
    color: #000C60;
    font-weight: 900;
    padding: 0;
    margin: 0;
}

.FiveBoxHomeBoxTitle p {
    font-size: 16px;
    line-height: 20.11px;
    font-weight: 400;
    font-family: Regular;
    color: #000C60;
    padding: 0;
    margin: 0;
}



.FiveBoxHomeRight {
    width: 404px;
    height: 349px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}

.FiveBoxHomeRight p:nth-child(1) {
    font-family: Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.6px;
    letter-spacing: 0.11em;
    text-align: left;
    color: var(--Light-blue, #257AFD);
    padding: 0;
    margin: 0;
}

.FiveBoxHomeRight h2 {
    width: 331px;
    height: 65px;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-weight: 900;
    font-size: 36px;
    line-height: 45.25px;
    font-family: BlackPro;
    box-shadow: 0px 0px 8px 0px #00000033;
    background: linear-gradient(75.79deg, #005EEB 2.37%, #257AFD 43.81%, #00F9FF 106.28%);
    padding: 0;
    margin: 0;
    margin-top: 4px;
}

.FiveBoxHomeRight p:nth-child(3) {
    font-family: Regular;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.63px;
    text-align: left;
    color: var(--Dark-gray, #2D2D2D);
    padding: 0;
    margin: 0;
    margin-top: 10px;
}

.FiveBoxHomeRight button {
    width: 166px;
    height: 48px;
    border-radius: 6px;
    border: none;
    background-color: #000C60;
    color: #fff;
    font-size: 18px;
    font-family: Regular;
    line-height: 22.63px;
    font-weight: 400;
    margin-bottom: 4px;
    cursor: pointer;
    transition: 1s ease all;
    position: relative;
    z-index: 30;
    overflow: hidden;
    margin-top: 20px;
}

.FiveBoxHomeRight button::after {
    content: '';
    position: absolute;
    height: 0.25rem;
    width: 0.25rem;
    background-color: #1AFFC3;
    left: 50%;
    bottom: 0;
    transform: translateY(100%);
    border-radius: 0.375rem;
    z-index: -20;
    transition: all 1s;
}

.FiveBoxHomeRight button:hover::after {
    transform: scale(300);
    transition: all 1s;
}

.FiveBoxHomeRight button:hover {
    transition: all 1s;
    color: #000C60;
}

.BackFiveBoxHomeRight {
    position: absolute;
    bottom: 37px;
    right: 0;
    z-index: -1;
}

@media (max-width:1116px){
    .FiveBoxHome {
        justify-content: center;
    }
    .FiveBoxHomeLeft_boxTwo img {
        width: 142px;
        height: 87px;
    }
    .FiveBoxHomeLeft {
        width: 100%;
        margin-right: 0;
        margin-bottom: 40px;
    }
    .FiveBoxHomeRight {
        width: 100%;
    }
}
@media (max-width:734px){
    .FiveBoxHomeLeft {
       background-position: center;
       background-size: 100% 100%;
    }
    .FiveBoxHomeLeft_boxTwo {
        bottom: -16px;
        left: -12px;
       
    }

    .FiveBoxHomeLeft_boxOne {
        top: -65px;
        right: 9px;
        
    }
    .FiveBoxHomeLeft_boxOne img {
        width: 200px;
        height: 120px;
    }
    
    .FiveBoxHomeLeft_boxTwo img {
        width: 102px;
        height: 57px;
    }
    .FiveBoxHomeLeft_sms {
        top: -38px;
        right: 29px;
        padding: 5px;
    }
    .FiveBoxHomeLeft {
        width: 100%;
    }
    .FiveBoxHomeRight {
        width: 100%;
    }
}


/* 5 section */

/* 6 section */

.SixBoxHomeBox {
    max-width: 1440px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 85px;
    padding-top: 15px;
    margin-bottom: 85px;
}


.SixBoxHomeBg {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0.6;
}



.SixBoxHomeBoxTitle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 60px;
}

.SixBoxHomeBoxTitle h2 {
    font-size: 42px;
    line-height: 52.79px;
    font-weight: 900;
    font-family: BlackPro;
    color: #000C60;
    padding: 0;
    margin: 0;
}

.SixBoxHomeBoxTitle p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    font-family: Regular;
    color: #000C60;
    padding: 0;
    margin: 0;
}

.SixBoxHome {
    max-width: 1440px;
    width: 100%;
    /* height: 700px; */
    padding: 0 60px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;

}

.SixBoxHomeBoxRight {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.SixBoxHomeBoxRightMac img {
    display: block;
    width: 100%;
    height: 100%;
}

.SixBoxHomeBoxRightText {
    max-width: 160px;
    width: 100%;
    height: 54px;
    border-radius: 14px;
    background: linear-gradient(75.79deg, rgba(0, 249, 255, 0.7) 2.37%, rgba(37, 122, 253, 0.7) 64.84%, rgba(0, 94, 235, 0.7) 106.28%);
    backdrop-filter: blur(11.300000190734863px);
    box-shadow: 0px 0px 9.8px 1px #00000040;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
    top: -22px;
    left: -20px;
}

.SixBoxHomeBoxRightText p {
    font-size: 20px;
    font-weight: 400;
    line-height: 25.14px;
    font-family: Regular;
    color: #FFF;
    padding: 0;
    margin: 0;
    text-shadow: 0px 0px 4.6px #00000040;
}

.SixBoxHomeBoxLeft {
    max-width: 373px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}

.SixBoxHomeBoxLeft p:nth-child(1) {
    font-family: Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.6px;
    letter-spacing: 0.11em;
    text-align: left;
    color: #257AFD;
    padding: 0;
    margin: 0;
}

.SixBoxHomeBoxLeft h2 {
    max-width: 312px;
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    background: linear-gradient(75.79deg, #005EEB 2.37%, #257AFD 43.81%, #00F9FF 106.28%);
    font-size: 36px;
    color: #FFF;
    font-family: BlackPro;
    line-height: 45.25px;
    font-weight: 900;
    text-shadow: 0px 0px 8px 0px #00000033;
    padding: 0;
    margin: 0;
    margin-top: 4px;
}

.SixBoxHomeBoxLeft p:nth-child(3) {
    font-family: Regular;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.63px;
    color: #2D2D2D;
    padding: 0;
    margin: 0;
    margin-top: 10px;
}

.SixBoxHomeBoxLeft button {
    width: 166px;
    height: 48px;
    border-radius: 6px;
    border: none;
    background-color: #000C60;
    color: #fff;
    font-size: 18px;
    font-family: Regular;
    line-height: 22.63px;
    font-weight: 400;
    margin-bottom: 4px;
    cursor: pointer;
    transition: 1s ease all;
    position: relative;
    z-index: 30;
    overflow: hidden;
    margin-top: 20px;
}

.SixBoxHomeBoxLeft button::after {
    content: '';
    position: absolute;
    height: 0.25rem;
    width: 0.25rem;
    background-color: #1AFFC3;
    left: 50%;
    bottom: 0;
    transform: translateY(100%);
    border-radius: 0.375rem;
    z-index: -20;
    transition: all 1s;
}

.SixBoxHomeBoxLeft button:hover::after {
    transform: scale(300);
    transition: all 1s;
}

.SixBoxHomeBoxLeft button:hover {
    transition: all 1s;
    color: #000C60;
}

@media (max-width:1084px){
    .SixBoxHome {
        align-items: center;
        justify-content: center;
    }
    .SixBoxHomeBoxLeft {
        max-width: 80%;
    }
    
}
@media (max-width:724px){
    .SixBoxHomeBoxRight {
        width: 100%;
    }
    .SixBoxHomeBoxLeft {
        max-width: 100%;
    }
    .SixBoxHomeBoxTitle h2 {
        font-size: 36px;
        line-height: 40px;
    }
}
@media (max-width:478px){
    .SixBoxHomeBoxLeft h2 {
        font-size: 26px;
        height: 45px;
    }
    .SixBoxHome {
        padding: 0 20px;
        flex-direction: column-reverse;
        align-items: flex-end;
    }
    .SixBoxHomeBoxLeft {
        align-items: flex-end;
    }
    .SixBoxHomeBoxLeft p {
        text-align: end;
    }
    .SixBoxHomeBoxTitle h2 {
        font-size: 28px;
        line-height: 32px;
    }
}

/* 6 section */

/* 7 section */

.DropBoxContant {
    max-width: 677px;
    width: 100%;
    min-height: 576px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 7px 20px 7px;
}

.DropBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.DropTitle {
    max-width: 677px;
    width: 100%;
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px 8px 20px;
    border-radius: 10px;
    border: 1px solid #D3D3D3;
    background: #FFF;
    cursor: pointer;
    transition: .3s ease all;
}

.DropTitle:hover {
    background: var(--Light-blue-hover, #D5ECFF);
    border: 1px solid #D5ECFF;
}

.Id {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 700;
    font-family: BoldPro;
    color: #2D2D2D;
}

.Id p {
    width: 25px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: 400;
    font-family: Regular;
    line-height: 17.6px;
    color: #000C60;
}

.DropContent {
    max-width: 677px;
    width: 100%;
    border: 1px solid var(--White, #FFFFFF);
    display: flex;
    align-items: flex-start;
}

.ContantOne {
    padding: 0px 20px 24px 20px;
    height: 100%;
}

.ContantOne p {
    font-size: 14px;
    line-height: 17.6px;
    font-weight: 400;
    font-family: Regular;
    color: #000C60;
    margin: 0;
    padding: 0;
}

.DropTitle.active {
    background: linear-gradient(30.88deg, #00F9FF -4.7%, #257AFD 19.61%, #005EEB 97.13%);
    border-radius: 10px 10px 0 0;
    border: 1ps solid #D3D3D3;
}

.DropTitle.active .Id {
    color: #FFF;
}

.DropTitle.active .Id p {
    color: #FFF;
}

.One_Box_ab {
    position: absolute;
    top: 195px;
    right: 210px;
    opacity: 0.6;
}

.Two_Box_ab {
    position: absolute;
    bottom: 61px;
    left: 187px;
    opacity: 0.6;
}

.Four_Box_ab {
    position: absolute;
    top: 87px;
    left: 0;
    opacity: 0.4;
}

.Three_Box_ab {
    position: absolute;
    bottom: 52px;
    right: 0;
    opacity: 0.4;
}

.SevenBoxHomeBox {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    position: relative;
}

.SevenBoxHomeBoxTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SevenBoxHomeBoxTitle h2 {
    font-size: 42px;
    line-height: 52.79px;
    font-family: BlackPro;
    color: #000C60;
    font-weight: 900;
    padding: 0;
    margin: 0;
    text-align: center;
}

.SevenBoxHomeBoxTitle p {
    text-align: center;
    font-size: 16px;
    line-height: 20.11px;
    font-family: Regular;
    color: #000C60;
    font-weight: 400;
    padding: 0;
    margin: 0;
}


@media (max-width:746px){
    .SevenBoxHomeBoxTitle h2 {
        font-size: 32px;
        line-height: 42px;
    }
    
}

/* 7 section */
/* 8 section */

.EghtBoxHomeBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
    padding: 0 5px;
}

.EightFormBox {
    max-width: 350px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    background: #FFFFFF80;
    border: 1px solid #EBEFF5;
    backdrop-filter: blur(8.800000190734863px);
    border-radius: 10px;
    padding: 26px 20px 20px 20px;
    
}

.EghtBoxHomeBoxTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 30px;
}

.EghtBoxHomeBoxTitle h2 {
    font-size: 42px;
    font-weight: 900;
    line-height: 52.79px;
    font-family: BlackPro;
    color: #000C60;
    padding: 0;
    margin: 0;
}

.EghtBoxHomeBoxTitle p {
    font-size: 16px;
    font-weight: 400;
    font-family: Regular;
    line-height: 20.11px;
    color: #000C60;
    padding: 0;
    margin: 0;
}

.EightFormBox label {
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.EightFormBox label p {
    font-size: 14px;
    line-height: 17.6px;
    font-weight: 400;
    font-family: Regular;
    color: #334D6E;
    padding: 0;
    margin: 0;
}


.EightFormBox label input {
    /* display: inline-block; */
    display: block;
    max-width: 300px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #D3D3D3;
    padding: 16px 20px 16px 20px;
    outline: none;
    font-family: Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    text-align: left;
    color: #707683;
}

.EightFormBox label input::placeholder {
    font-family: Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    text-align: left;
    color: #707683;
}

.EightFormBox button {
    max-width: 300px;
    width: 100%;
    height: 48px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: #000C60;
    margin-top: 10px;
    color: #FFF;
    font-family: Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    text-align: left;
    cursor: pointer;
    transition: 1s ease all;
    position: relative;
    z-index: 30;
    overflow: hidden;
}

.EightFormBox button::after {
    content: '';
    position: absolute;
    height: 0.25rem;
    width: 0.25rem;
    background-color: #1AFFC3;
    left: 50%;
    bottom: 0;
    transform: translateY(100%);
    border-radius: 0.375rem;
    z-index: -20;
    transition: all 1s;
}

.EightFormBox button:hover::after {
    transform: scale(300);
    transition: all 1s;
}

.EightFormBox button:hover {
    transition: all 1s;
    color: #000C60;
}


.EightlastBox {
    max-width: 654px;
    width: 100%;
    padding: 40px 34px 30px 34px;
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -3.27%, rgba(213, 236, 255, 0.5) 100%);
    backdrop-filter: blur(8.800000190734863px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.EightlastBoxTitle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.EightlastBoxTitle h2 {
    padding: 0;
    margin: 0;
    font-family: BlackPro;
    font-size: 25px;
    font-weight: 900;
    line-height: 31.42px;
    text-align: center;
    color: #000C60;
}


.EightlastBoxTitle p {
    font-family: Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    text-align: center;
    color: #000C60;
    padding: 0;
    margin: 0;
}

.EightlastBox button {
    width: 166px;
    height: 48px;
    border-radius: 6px;
    border: none;
    background-color: #000C60;
    color: #fff;
    font-size: 18px;
    font-family: Regular;
    line-height: 22.63px;
    font-weight: 400;
    margin-bottom: 4px;
    cursor: pointer;
    transition: 1s ease all;
    position: relative;
    z-index: 30;
    overflow: hidden;
    margin-top: 30px;
}

.EightlastBox button::after {
    content: '';
    position: absolute;
    height: 0.25rem;
    width: 0.25rem;
    background-color: #1AFFC3;
    left: 50%;
    bottom: 0;
    transform: translateY(100%);
    border-radius: 0.375rem;
    z-index: -20;
    transition: all 1s;
}

.EightlastBox button:hover::after {
    transform: scale(300);
    transition: all 1s;
}

.EightlastBox button:hover {
    transition: all 1s;
    color: #000C60;
}

.Contacts {
    max-width: 730px;
    width: 100%;
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #EBEFF5;
    backdrop-filter: blur(8.800000190734863px);
    margin-top: 31px;
}


.Footer {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000C60;
}

.Footer p {
    font-family: Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    text-align: left;
    color: #FFFFFF;
}

@media (max-width:746px){
    
    .EightlastBox {
        padding: 20px 14px 10px 14px;
    }
    .EightFormBox label input  {
        padding: 12px 10px 12px 20px;
    }
    
    .EightlastBoxTitle {
        max-width: 300px;
        width: 100%;
    }
}

/* 8 section */

/* murod */



.Insta,
.Telegram,
.telefon {
    display: flex;
    align-items: center;
}

.Insta span,
.Telegram span,
.telefon span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
}

.Insta h2,
.Telegram h2,
.telefon h2 {
    padding: 0;
    margin: 0;
    font-family: MediumPro;
    font-size: 16px;
    font-weight: 500;
    line-height: 20.11px;
    text-align: left;
    color: #000C60;
}

.Insta p,
.Telegram p,
.telefon p {
    font-family: Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    text-align: left;
    color: #000C60;
    padding: 0;
    margin: 0;
}

.Backgc {
    position: absolute;
    top: 70px;
    z-index: -1;
}


.HomeBackgroundCrmImg {
    perspective: 100px;
    transition: transform 0.1s ease;
    transform-style: preserve-3d;
    will-change: transform;
}

.HomeBackgroundmobile {
    display: none;
}

@media (max-width:1439px) {
    .Home {
        width: 100%;
    }

    .HomeBackgroundTitle h2 {
        font-size: 2.9187vw;
        line-height: 3.6685vw;
    }

    .HomeBackgroundTitle p:nth-child(2) {
        font-size: 1.3899vw;
        line-height: 1.7470vw;
        margin-bottom: 1.6678vw;
    }

    .HomeBackgroundTitle p:nth-child(4) {
        font-size: 0.8339vw;
        line-height: 1.0479vw;
        margin-bottom: 3.3356vw;
    }

    

    .SecondBoxHomeTitle p {
        font-size: 15px;
        margin: 10px 45px;
    }

    .HomeBackground {
        width: 100%;
        height: 86.2404vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .HomeBackgroundBg {
        width: 97.2898vw;
        height: 62.5434vw;
    }

    .HomeBackgroundCrmImg {
        width: 72.9673vw;
        height: 45.5872vw;
    }

    .HomeBackgroundTitle button {
        width: 11.5358vw;
        height: 3.3356vw;
        border-radius: 0.4170vw;
        font-size: 1.2509vw;
        margin-bottom: 0.2780vw;
    }

    .HomeBackgroundCrmImg img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .SecondBoxHome {
        width: 100%;
        padding: 0;
    }

    .SecondBoxHomeContant {
        width: 100%;
        justify-content: center;
        gap: 1.3899vw;
        height: 43.7804vw;
        padding: 0;
        margin-top: 0.6949vw;
    }

    .SecondDawn p {
        font-size: 1.1119vw;
        line-height: 1.3975vw;
    }

    .SecondBoxHomeContantLeft_DownBox .Users {
        width: 6.6018vw;
        height: 6.6018vw;
    }

    .SecondBoxHomeContantLeft_Up .Title {
        gap: 0.2780vw;
    }

    .SecondBoxHomeContantRight {
        height: 36.2752vw;
        padding: 3.7526vw 1.0424vw;
    }

    .SecondBoxHomeContantLeft_Down {
        gap: 1.3899vw;
    }

    .icon {
        z-index: -1;
        width: 23.8972vw;
        height: 12.7561vw;
    }

    .icon img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .SecondBoxHomeContantRight .icon {
        width: 13.8985vw;
        bottom: -10px;
        right: -10px;
    }

    .SecondBoxHomeContantLeft_Up::after {
        left: 16.212vw;
        top: 25.6442vw;
    }

    .SecondBoxHomeContantLeft_DownBox::after {
        left: 19.212vw;
        top: 24.5594vw;
    }

    .SecondBoxHomeContantRight::after {
        left: 45.7832vw;
        top: 15.9493vw;
    }

    .TheardBoxHome {
        width: 100%;
        /* height: 62.5434vw; */
        gap: 0.6254vw;
    }

    .TheardBoxHomeTitle h2 {
        font-size: 2.9187vw;
        line-height: 3.6685vw;
    }

    .TheardBoxHomeTitle p {
        font-size: 1.1119vw;
        line-height: 1.3975vw;
    }


    .TheardBoxHomeBoxLeftImg img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .TheardBoxHomeBox {
        width: 100%;
        height: 62.5434vw;
    }

    .Boxsms_One {
        top: 1.3899vw;
        left: 2.8492vw;
        width: 10.6324vw;
        height: 9.7985vw;
        border-radius: 0.4170vw;
        padding: 0.6949vw;
    }

    .Boxsms_Two {
        left: 8.9646vw;
        bottom: 0;
        width: 10.6324vw;
        height: 9.7985vw;
        border-radius: 0.4170vw;
        padding: 0.6949vw;

    }

    .Boxsms_Three {
        right: 0;
        bottom: 8.0612vw;
        width: 10.6324vw;
        height: 9.7985vw;
        border-radius: 0.4170vw;
        padding: 0.6949vw;
    }

    .TheardBoxHomeBoxLeft {
        width: 100%;
        height: 47.7415vw;
    }

    .Boxsms_OneTop span {
        font-size: 1.1119vw;
    }

    .Boxsms_OneTop p {
        font-size: 0.8339vw;
        line-height: 1.1258vw;
    }

    .Boxsms_OneMidDars {
        width: 6.4628vw;
        height: 1.3204vw;
        border-radius: 0.2780vw;
        gap: 0.2780vw;
    }

    .Boxsms_OneMidDars p {
        font-size: 0.8339vw;
        line-height: 1.1258vw;
        margin-top: 0.2085vw;
    }

    .Boxsms_OneMidDars span {
        font-size: 1.1119vw;
    }

    .Boxsms_OneMid_info p {
        font-size: 0.8339vw;
        line-height: 1.1258vw;
    }

    .Boxsms_OneBottom p {
        font-size: 0.6949vw;
        line-height: 0.9382vw;
        margin-right: 0.6949vw;
    }

    .Boxsms_OneMid_info {
        gap: 0.6949vw;
    }

    .Boxsms_OneMid_info span {
        width: 1.1119vw;
        height: 1.1119vw;
    }

    .Boxsms_OneMid_info span img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .TheardBoxHomeBoxRight {
        width: 28.8395vw;
        height: 16.2613vw;
        margin-top: 25.8798vw;
        left: 2.6949vw;
        /* position: relative; */
    }

    .FourBoxHomeLeft {
        width: 28.8395vw;
        height: 16.2613vw;
    }

    .TheardBoxHomeBoxRight p:nth-child(1) {
        font-size: 0.9729vw;
        line-height: 1.2231vw;
    }

    .TheardBoxHomeBoxRight h2 {
        width: fit-content;
        height: 6.5170vw;
        margin-top: 0.2780vw;
        padding: 15px 30px;
        border-radius: 0.9729vw;
        font-size: 16px;
        line-height: 3.1445vw;
    }

    .TheardBoxHomeBoxRight p:nth-child(3) {
        font-size: 1.2509vw;
        line-height: 1.5726vw;
        margin-top: 0.6949vw;
    }

    .TheardBoxHomeBoxRight button {
        width: 11.5358vw;
        height: 3.3356vw;
        border-radius: 0.4170vw;
        line-height: 1.5726vw;
        font-size: 1.2509vw;
        margin-bottom: 0.2780vw;
        margin-top: 1.3899vw;
    }

    .LineGrande {
        width: 100vw;
        bottom: 3.1272vw;
    }

    .LineGrande img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .FourBoxHomeBox,
    .FourBoxHome {
        width: inherit;
        height: inherit;
    }

    .FourBoxHomeRight {
        width: 69.7012vw;
        height: 46.2126vw;
    }

    .FourBoxImg {
        width: 100%;
    }

    .FourBoxImg img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .FourBoxSms {
        top: 2.3628vw;
        right: 2.1543vw;
        width: 17.7901vw;
        height: 5.0035vw;
        padding: 0.6949vw;
        font-size: 0.9729vw;
        line-height: 1.2231vw;
        border-radius: 0.4170vw;
    }

    .FourBoxOne,
    .FourBoxTwo {
        width: 14.8714vw;
        height: 9.5205vw;
        border-radius: 2.0848vw;
    }

    .FourBoxOne img,
    .FourBoxTwo img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .FourBoxHomeLeft p:nth-child(1) {
        font-size: 0.9729vw;
        line-height: 1.2231vw;
    }

    .FourBoxHomeLeft h2 {
        width: 26.5462vw;
        height: 4.5170vw;
        gap: 0.6949vw;
        border-radius: 0.9729vw;
        font-size: 2.5017vw;
        line-height: 3.1445vw;
        margin-top: 0.2780vw;
    }

    .FourBoxHomeLeft p:nth-child(3) {
        font-size: 1.2509vw;
        line-height: 1.5726vw;
        text-align: right;
    }

    .FourBoxHomeLeft button {
        width: 11.5358vw;
        min-height: 3.3356vw;
        border-radius: 0.4170vw;
        font-size: 1.2509vw;
        line-height: 1.5726vw;
        margin-top: 1.3899vw;
    }

    .FourBoxHomeTitle h2 {
        font-size: 2.9187vw;
        line-height: 3.6685vw;
    }

    .FourBoxHomeTitle p {
        font-size: 1.1119vw;
        line-height: 1.3975vw;
        margin-bottom: 0.4864vw;
    }


    .Backgc {
        width: 100%;
    }

    .Backgc img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

#prob {
    display: none;
}

@media (max-width: 768px) {
    .HomeBackgroundBg {
        display: none;
    }

    .SecondBoxHomeContant {
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .SecondBoxHomeContantLeft {
        flex-direction: column;
    }

    .HomeBackgroundmobile {
        display: block;
        position: absolute;
        width: 100%;
        height: 804px;
        top: 0;
        left: 0;
        background-image: url(./../../Assets/Mobile/bg_mobile.png);
        background-position: start;
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
    }
}

@media (max-width: 768px) {
    .HomeBackground {
        height: 189.176vw;
    }

    .HomeBackgroundTitle {
        top: calc(180px + 1vh);
    }

    .HomeBackgroundCrmImg {
        width: 92.25%;
    }

    .HomeBackgroundCrmImg {
        top: 64.323vw;
    }

    .HomeBackgroundTitle h2 {
        font-size: 40px;
        line-height: 52.79px;
        text-align: center;
    }

    .HomeBackgroundTitle h2 span {
        text-align: center;
    }

    .HomeBackgroundTitle p:nth-child(2) {
        font-size: 20px;
        line-height: 25.14px;
        padding: 0 50px;
        margin-bottom: 24px;
    }

    .HomeBackgroundTitle button {
        width: 166px;
        height: 48px;
        border-radius: 6px;
        font-size: 18px;
        line-height: 22.63px;
    }

    .HomeBackgroundTitle p:nth-child(4) {
        font-size: 12px;
        line-height: 15.08px;
        margin-top: 4px;
        margin-bottom: 8px;
    }

    .HomeBackgroundCrmImg {
        width: 92.235vw;
        height: 58.353vw;
        border-radius: 20px;
        top: 200px;
        position: relative;
    }

    .NavLeft {
        width: 151.15px;
        height: 62.37px;
    }

    .SecondBoxHome {
        position: relative;
        margin-top: 7.059vw;
    }

    .SecondBoxHomeTitle h2 {
        font-size: 30px;
        line-height: 35px;
        margin-top: 50px;
    }

    .SecondBoxHomeContantLeft_Down {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .SecondBoxHomeContantRight{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
    }
    .TheardBoxHome{
        /* width: 51.08vw; */
        display: flex;
        /* margin: 770px auto 320px auto; */
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .TheardBoxHomeBox{
        /* width: 60.08vw; */
        height: auto;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        /* margin: 0 20px; */
    }
    .TheardBoxHomeBoxRight{
        width: 100%;
        height: auto;
        padding: 5px;
        display: flex;
        align-items: start;
        justify-content: space-between;
        gap: 5px;
    }
    .TheardBoxHomeBoxRight p:nth-child(1){
        font-size: 14px;
        line-height: 15px;
    }
    .TheardBoxHomeBoxRight p:nth-child(3){
        font-size: 14px;
        line-height: 21.08px;
        text-align: start;
    }
    .TheardBoxHomeBoxRight h2{
        width: fit-content;
        height: 6.5170vw;
        margin-top: 0.2780vw;
        padding: 20px 30px;
        border-radius: 0.9729vw;
        font-size: 16px;
        line-height: 3.1445vw;
    }
    .TheardBoxHomeBoxRight button{
        width: 120px;
        height: 40px;
        border-radius: 6px;
        font-size: 14px;
        line-height: 19.37px;
        /* background-color: var(--Colors-primary-50, #3D85FF); */
        color: #FFFFFF;
        border: none;
        cursor: pointer;
    }
    .TheardBoxHomeTitle h2{
        font-size: 40px;
        line-height: 36px;
        margin-bottom: 25px;
    }
    .TheardBoxHomeTitle p{
        font-size: 14px;
        line-height: 18px;
    }
    .Boxsms_Three {
        position: absolute;
        right: 5px;
        bottom: 40px;
        width: 60px;
        height: 50px;
        border-radius: 6px;
        padding: 10px;
        box-shadow: 0px 0px 20px 0px #00000040;
        backdrop-filter: blur(8.800000190734863px);
        border: 1px solid var(--Colors-Light-gray-30, #EBEFF5);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: .3s ease all;
        cursor: pointer;
    }
    .Boxsms_Three:hover {
        bottom: 50px;
    }
    .Boxsms_One {
        display: none !important;
        position: absolute;
        right: 5px;
        bottom: 40px;
        width: 60px;
        height: 50px;
        border-radius: 6px;
        padding: 10px;
        box-shadow: 0px 0px 20px 0px #00000040;
        backdrop-filter: blur(8.800000190734863px);
        border: 1px solid var(--Colors-Light-gray-30, #EBEFF5);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: .3s ease all;
        cursor: pointer;
    }
    .Boxsms_One:hover {
        bottom: 50px;
    }
    .Boxsms_Two {
        position: absolute;
        display: none !important;
        right: 5px;
        bottom: -106px;
        width: 60px;
        height: 50px;
        border-radius: 6px;
        padding: 10px;
        box-shadow: 0px 0px 20px 0px #00000040;
        backdrop-filter: blur(8.800000190734863px);
        border: 1px solid var(--Colors-Light-gray-30, #EBEFF5);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: .3s ease all;
        cursor: pointer;
    }
    .Boxsms_Two:hover {
        bottom: 10px;
    }
    .Boxsms_Three {
        top: 10px;
        display: none !important;
    }
    .SecondBoxHome .SecondDawn p{
        font-size: 16px;
        text-align: center;
        line-height: 22.63px;
        margin: 30px 10px;
    }
    .LineGrande img{
        display: none;
    }
    .FourBoxHomeBox {
        width: 99%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
    }
    .FourBoxHome{
        /* width: 51.08vw;
        height: 450px; */
        display: flex;
        margin: 40px auto;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
    }
    .FourBoxBgc{
        display: none;
    }
    .FourBoxTwo{
        display: none;
    }
    .FourBoxHomeTitle {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .FourBoxHomeTitle h2 {
        font-size: 40px;
        line-height: 36px;
        margin-bottom: 25px;
    }
    .FourBoxHomeTitle p {
        font-size: 12px;
        line-height: 15.08px;
        margin: 0 20px;
    }
    
    .FourBoxHomeRight{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .FourBoxHomeLeft{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: end;
        gap: 10px;
        margin: 20px 0 10px 0px;
    }
    .FourBoxHome .FourBoxHomeLeft h2{
        width: fit-content;
        height: 6.5170vw;
        margin-top: 0.2780vw;
        padding: 20px 30px;
        border-radius: 0.9729vw;
        font-size: 16px;
        line-height: 3.1445vw;
    }
    .FourBoxHome .FourBoxHomeLeft p:nth-child(1){
        font-size: 12px;
        line-height: 13.08px;
        /* margin: 0 20px; */
    }
    .FourBoxHome .FourBoxHomeLeft p:nth-child(3){
        font-size: 14px;
        line-height: 21.08px;
    }
    .FourBoxHome .FourBoxHomeLeft button{
        width: 120px;
        height: 40px;
        border-radius: 6px;
        font-size: 14px;
        line-height: 19.37px;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
    }

    .FourBoxImg {
        position: inherit !important;
        top: 0;
        bottom: 0;
        right: 0;
        height: inherit !important;
    }
    
    .FourBoxImg img {
        /* position: absolute; */
        width: 100%;
        height: auto;
    }

}

@media (max-width:565px) {

    #prob {
        width: 5px;
        display: inline;
    }
    .SecondDawn p{
        font-size: 18px;
        text-align: center;
        line-height: 22.63px;
        margin: 30px 5px;
    }

    .FourBoxSms, .FourBoxOne {
        display: none !important;
    }
}