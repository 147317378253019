@font-face {
    font-family: Regular;
    src: url(./Assets/fonts/Cera\ Pro\ Regular.otf);
}

@font-face {
    font-family: BoldPro;
    src: url(./Assets/fonts/Cera\ Pro\ Bold.otf );
}

@font-face {
    font-family: BlackPro;
    src: url(./Assets/fonts/Cera\ Pro\ Black.otf );
}

@font-face {
    font-family: MediumPro;
    src: url(./Assets/fonts/Cera\ Pro\ Medium.otf );
}

@font-face {
    font-family: RegularGen;
    src: url(./Assets/GeneralFonts/GeneralSans-Regular.otf);
}

@font-face {
    font-family: MediumGen;
    src: url(./Assets/GeneralFonts/GeneralSans-Medium.otf);
}

@font-face {
    font-family: SamiBold;
    src: url(./Assets/GeneralFonts/GeneralSans-Semibold.otf );
}

@font-face {
    font-family: BoldGen;
    src: url(./Assets/GeneralFonts/GeneralSans-Bold.otf);
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.App {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AppNavbar {
    display: flex;
    justify-content: center;
    padding: 60px 0px 20px 0px;
}