.Navbar {
    position: absolute;
    left: 50%;
    top: 60px;
    transform: translateX(-50%);
    width: 1267px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    background-color: #FFF;
    padding: 10px 20px 10px 20px;
    transition: .3s ease all;
    cursor: pointer;
    z-index: 1;
}

.Navbar:hover {
    box-shadow: 0px 0px 20px 0px #00000040;
}

.NavLeft {
    width: 151.15px;
    height: 62.37px;
    display: flex;
    align-items: center;
}

.NavMidl {
    width: 654px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.NavMidl button {
    min-width: 83px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    color: #2D2D2D;
    border: none;
    background: none;
    cursor: pointer;
    transition: .3s ease all;
    border-radius: 6px;
}

.NavMidl button:hover {
    background: #D5ECFF;
}

.NavRight {
    display: flex;
    align-items: center;

}

.LangBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
}

.LangBox p:nth-child(1) {
    width: 69px;
    height: 40px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    color: #2D2D2D;
    padding: 0;
    margin: 0;
    transition: .3s ease all;
    cursor: pointer;
}

.LangBox p:nth-child(1):hover {
    background: #D5ECFF;
}

.LangBox p:nth-child(3) {
    width: 81px;
    height: 40px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    color: #2D2D2D;
    cursor: pointer;
    transition: .3s ease all;
    margin-right: 6px;
}

.LangBox p:nth-child(3):hover {
    background-color: #D5ECFF;
}

.LangBox span {
    width: 1px;
    height: 40px;
    background-color: #2D2D2D;
}

.NavRight button {
    width: 152px;
    height: 40px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #005EEB;
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    font-family: Regular;
    border: none;
    cursor: pointer;
    transition: .3s ease all;
}

.NavRight button:hover {
    background-color: #257AFD;
}

.dropdown {
    width: 69px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    box-shadow: 0px 0px 9px 0px #00000033;
    background: #FFFFFF;
    bottom: -110px;
}

.dropdown ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
}

.dropdown ul li {
    list-style: none;
    width: 69px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    text-align: left;
    color: var(--Colors-Dark-gray-gray, #707683);

}

.activate,
.activeKirish {
    background-color: #70BEFF !important;
}

.NavRight_Mobile {
    display: none;
}

@media (max-width:1439px) {
    .Navbar {
        width: 90.8270vw;
        /* height: 4.4475vw; */
        /* padding: 0.6949vw 1.3899vw; */
    }

    .NavMidl {
        width: 45.4482vw;
        height: 2.7797vw;
        gap: 0.6949vw;
    }

    .NavMidl button {
        min-width: 5.7679vw;
        height: 2.7797vw;
        font-size: 1.1119vw;
        line-height: 1.3975vw;
        border-radius: 0.4170vw;
    }

    .NavLeft {
        width: 10.5038vw;
        height: 4.3343vw;
    }

    .LangBox p:nth-child(1) {
        width: 4.7950vw;
        height: 2.7797vw;
        border-radius: 0.4170vw;
        font-size: 1.1119vw;
        line-height: 1.3975vw;
    }

    .LangBox p:nth-child(3) {
        width: 5.6289vw;
        height: 2.7797vw;
        border-radius: 0.4170vw;
        font-size: 1.1119vw;
        margin-right: 0.4170vw;
        line-height: 1.3975vw;
    }

    .LangBox span {
        width: 0.0695vw;
        height: 2.7797vw;
    }

    .NavRight button {
        width: 10.5629vw;
        height: 2.7797vw;
        font-size: 1.1119vw;
        border-radius: 0.4170vw;
        line-height: 1.3975vw;
    }
}


/* Общие стили */
.Navbar {
    display: flex;
    /* height: 44px; */
    /* padding: 20px; */
    transition: height 0.3s ease;
}

.NavTop {
    display: none;
}

.DropdownMenu {
    display: none;
}


/* Мобильная версия */
@media (max-width: 768px) {
    .Navbar {
        width: 84.706vw;
        display: flex;
        flex-direction: column;
        /* height: 44px; */
        /* padding: 20px; */
        transition: height 0.3s ease;
    }

    .Navbar.NavPen {
        height: auto;
        transition: height 0.3s ease;
        z-index: 100;
    }

    .NavTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 44px;
        width: 100%;
        transition: height 0.3s ease;
    }

    .NavMidl,
    .NavRight {
        display: none;
    }

    .NavRight_Mobile {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        gap: 20px;
        align-items: center;
        transition: height 0.3s ease;
    }

    .Lang_mob button,
    .Menu_mob button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .DropdownMenu {
        width: 100%;
        display: block;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.3s ease;
    }

    .DropdownMenu.open {
        max-height: 500px;
        margin-top: 20px;
    }

    .DropdownMenu ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .DropdownMenu ul li {
        padding: 10px;
        width: 241px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #D3D3D3;
    }

    .DropdownMenu ul li button {
        width: 100%;
        height: 100%;
        text-align: left;
        background: none;
        border: none;
        cursor: pointer;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3.765vw;
        line-height: 4.732vw;
        font-family: Regular;
        font-weight: 400;
    }

    .Last_lang {
        border: none !important;
    }

    .First {
        display: none;
    }

    .Last_Link_button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }

    .Last_Link_button button {
        width: 56.706vw;
        height: 9.412vw;
        font-size: 3.765vw;
        border-radius: 1.412vw;
        line-height: 4.732vw;
        font-weight: 400;
        font-family: Regular;
        color: #FFF;
        background: #005EEB;
        border: none;
    }
    
}